@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700|Roboto+Slab:400,700|Material+Icons");
.widget {
  margin-bottom: 50px;
}
.widget img {
  max-width: 100%;
}
.widget--news .widget--news__list,
.widget--news .widget--news__listitem {
  margin: 0;
  padding: 0;
}
.widget--news .widget--news__list {
  list-style: none;
}
.widget--news .widget--news__listitem {
  margin-bottom: 15px;
}
.widget--preview {
  padding: 10px;
}
html.wh-widgetpreview .widget {
  max-width: 300px;
}