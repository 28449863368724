.sportlinkoverviewtable {
  width: 100%;
}
.sportlinkoverviewtable tr {
  background: #fff;
}
.sportlinkoverviewtable tr:nth-child(even) {
  background: #f9f9f9;
}
.sportlinkoverviewtable th,
.sportlinkoverviewtable td {
  border: 1px solid #ddd;
  padding: 4px 6px;
}
.sportlinkoverviewtable th {
  padding: 4px;
  background: #f5f5f5;
}