.emb-news {
  padding: 20px 0;
}
.emb-news__readmorelink {
  text-decoration: underline;
}
html.non-touch .emb-news__readmorelink:hover {
  text-decoration: none;
}
.emb-news__image {
  display: block;
}
.emb-news .card-blog {
  margin-bottom: 0;
}