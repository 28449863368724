.pagenav {
  display: flex;
  justify-content: space-between;
}
.pagenav--hidden {
  visibility: hidden;
}
.pagenav .disabled {
  cursor: default;
  color: #ccc;
}
.pagenav .disabled, .pagenav .disabled:hover {
  text-decoration: none;
}
.pagenav__pages {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.pagenav__pages li {
  display: inline-block;
  margin: 0 5px;
}
.pagenav__pages li.active a {
  font-weight: bold;
}

.news__items {
  list-style-type: none;
  padding: 0;
}
.news__item-li {
  margin-bottom: 40px;
}
.news__item {
  margin: 10px 0 25px;
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
}
.news__image-container {
  width: 25%;
}
.news__image {
  display: block;
  max-width: 100%;
  height: auto;
  transition: transform 0.3s;
}
.news__image-link {
  overflow: hidden;
  display: block;
}
.news__image-link:hover .news__image {
  transform: scale(1.1);
}
.news__info {
  width: 75%;
  padding-left: 25px;
}
.news__date {
  margin-bottom: 10px;
}
.news__heading {
  margin-top: 0;
}
.news__readmore-container {
  margin-top: 10px;
}
.news--small .news__summary,
.news--small .news__readmore-container {
  display: none;
}
.news--small .news__item,
.news--small .news__item-li {
  margin-bottom: 0;
}
.news--small .news__image-container {
  width: 20%;
}
.news--small .news__info {
  width: 80%;
  padding-left: 20px;
}
@media screen and (max-width: 768px) {
  .news__image-container, .news--small .news__image-container {
    width: 100%;
  }
  .news__info, .news--small .news__info {
    width: 100%;
    padding-left: 0;
  }
}

.newsdetails__image {
  margin: 0 0 15px;
}
.newsdetails__image img {
  max-height: 200px;
  width: auto;
}
.newsdetails__back {
  margin-bottom: 10px;
}
.newsdetails__date {
  margin-bottom: 20px;
}
.newsdetails__addthis {
  margin: 0 0 25px;
}