.page-header--with-overlay .container .row {
  opacity: 0;
  transition: opacity 1.5s;
}
.page-header .show .container .row {
  opacity: 1;
}

.nope-header-slideshow {
  position: relative;
}
.nope-header-slideshow:after, .nope-header-slideshow:before {
  pointer-events: none;
}
.nope-header-slideshow.page-header {
  background: none;
  height: 70vh;
  max-height: 700px;
}
.nope-header-slideshow__item {
  position: relative;
  width: 100vw;
}
.nope-header-slideshow__item {
  display: flex;
  justify-content: center;
  align-items: center;
}
.nope-header-slideshow__bgimage {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.nope-header-slideshow__title, .nope-header-slideshow__subtitle {
  line-height: 100%;
  padding: 0 25px;
  color: #fff;
  text-align: center;
}
.nope-header-slideshow__title::after, .nope-header-slideshow__subtitle::after {
  display: none;
}
.nope-header-slideshow__title {
  font-size: 60px;
  margin: 0 0 20px;
}
.nope-header-slideshow__subtitle {
  font-size: 40px;
  margin: 0 0 20px;
}
.nope-header-slideshow__button {
  color: #fff;
  border-color: #fff;
}
.nope-header-slideshow__overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 10;
  top: 0;
  left: -100px;
  transform: skew(15deg, 0deg) translateX(-50%);
  margin-left: -100vw;
  transition: margin 1s;
}
.nope-header-slideshow__overlay.show {
  margin-left: 0;
}
.nope-header-slideshow__info {
  z-index: 20;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.nope-header-slideshow .swiper-container {
  height: 100%;
  width: 100%;
}
.nope-header-slideshow .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  border-radius: 2px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  background: #fff;
}
.nope-header-slideshow .swiper-pagination-bullet-active {
  transform: scale(1.5);
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
}
.nope-header-slideshow .swiper-button-prev {
  left: 30px;
}
.nope-header-slideshow .swiper-button-next {
  right: 30px;
}