.p-agenda__items {
  margin: 25px 0 0;
  padding: 0;
}
.p-agenda__item {
  margin: 10px 0 25px;
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
}
.p-agenda__image-container {
  width: 25%;
}
.p-agenda__image {
  display: block;
  max-width: 100%;
  height: auto;
  transition: transform 0.3s;
}
.p-agenda__image-link {
  overflow: hidden;
  display: block;
}
.p-agenda__image-link:hover .p-agenda__image {
  transform: scale(1.1);
}
.p-agenda__info {
  width: calc(75% - 25px);
  padding-left: 25px;
}
.p-agenda__date {
  margin-bottom: 10px;
}
.p-agenda__heading {
  margin-top: 0;
}
.p-agenda__readmore-container {
  text-align: right;
  margin-top: 10px;
}
@media screen and (max-width: 768px) {
  .p-agenda__image-container {
    width: 100%;
  }
  .p-agenda__info {
    width: 100%;
    padding-left: 0;
  }
}

.p-agendadetails__back {
  margin-bottom: 10px;
}
.p-agendadetails__date {
  margin-bottom: 20px;
}