.emb-textimagecols__cols {
  display: flex;
  justify-content: space-between;
}
.emb-textimagecols__cols--mirrored .emb-textimagecols__col--image {
  order: 1;
}
.emb-textimagecols__cols--mirrored .emb-textimagecols__col--text {
  order: 2;
  margin: 0 0 0 50px;
}
.emb-textimagecols__col--image {
  width: 50%;
}
.emb-textimagecols__col--text {
  display: flex;
  align-self: center;
  width: 50%;
  margin: 0 50px 0 0;
}
.emb-textimagecols__image {
  display: block;
  width: 100%;
}
.emb-textimagecols__text > *:first-child {
  margin-top: 0;
}
.emb-textimagecols__text > *:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 768px) {
  .emb-textimagecols__title {
    margin-top: 0;
    padding-right: 0;
  }
  .emb-textimagecols__cols {
    flex-direction: column;
  }
  .emb-textimagecols__cols .emb-textimagecols__title,
  .emb-textimagecols__cols .emb-textimagecols__text, .emb-textimagecols__cols--mirrored .emb-textimagecols__title,
  .emb-textimagecols__cols--mirrored .emb-textimagecols__text {
    padding-left: 0;
    padding-right: 0;
  }
  .emb-textimagecols__cols--mirrored .emb-textimagecols__col--text {
    margin: 25px 0 0 0;
    padding-left: 0;
    padding-right: 0;
  }
  .emb-textimagecols__cols--mirrored .emb-textimagecols__col--image {
    margin-top: 0;
  }
  .emb-textimagecols__col {
    width: 100%;
  }
  .emb-textimagecols__col--text {
    padding-left: 0;
    padding-right: 0;
    margin: 25px 0 0 0;
  }
  .emb-textimagecols__col--image {
    margin-top: 25px;
  }
}