.emb-logoslider * {
  min-height: 0;
  min-width: 0;
}
.emb-logoslider__slider {
  overflow: hidden;
}
.emb-logoslider__slide {
  display: flex;
  justify-content: center;
}
.emb-logoslider__slide img {
  max-width: 100%;
}
.emb-logoslider__navigationcontainer {
  text-align: center;
  position: relative;
  z-index: 10;
  width: 100%;
  height: 70px;
}
.emb-logoslider__navigation {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  background: #F2F1F2;
  margin: 0 12px;
  color: #000;
}
html.non-touch .emb-logoslider__navigation:hover {
  text-decoration: none;
}