.search {
  width: 100%;
}
.search__words {
  font-weight: bold;
}
.search__item {
  margin-bottom: 15px;
}
.search__title,
.search__title a {
  font-weight: bold;
}
.search .match {
  font-weight: bold;
}