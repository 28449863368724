.emb-twocolumnstext__cols {
  display: flex;
  justify-content: space-between;
}
.emb-twocolumnstext__col {
  width: 50%;
}
.emb-twocolumnstext__col--left {
  margin-right: 8px;
}
.emb-twocolumnstext__col--right {
  margin-left: 8px;
}
.emb-twocolumnstext__col .wh-rtd > *:first-child {
  margin-top: 0;
}
@media screen and (max-width: 768px) {
  .emb-twocolumnstext__cols {
    flex-direction: column;
  }
  .emb-twocolumnstext__col {
    width: 100%;
  }
}