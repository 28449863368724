.emb-projects__description {
  margin-bottom: 40px;
}
.emb-projects__image {
  margin: 20px 0;
  display: block;
}
.emb-projects__link {
  position: relative;
  display: block;
}
.emb-projects__link:hover .emb-projects__overlay {
  opacity: 1;
}
.emb-projects__overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px;
  opacity: 0;
  pointer-events: none;
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s;
  color: #fff;
}